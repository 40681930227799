<template>
  <div class="createWorkTalent">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="item-l"><img :src="item.topicPicture" alt="" /></div>
        <div class="item-r">
          <div class="name">{{ item.topicTitle }}</div>
          <div class="intro">
            {{ item.remark }}
          </div>
        </div>
        <!-- <div class="more">查看更多</div> -->
      </div>
    </v-list>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTalentListUrl } from "./api.js";

import { gloabalCount } from "@/utils/common";
export default {
  name: "createWorkTalent",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    gloabalCount("", 40, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    getTalentList() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
        topicType: 6, // 1. 和伙人风采 2.超话 3. 活动 6.创业达人
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios.get(`${getTalentListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
              item.activityPicture = this.$handleImg(
                400,
                300,
                item.activityPicture
              );
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getTalentList();
    },
    toDetail(item) {
      //   let params = {
      //     activityId: item.activityId,
      //   };
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      //   });
      this.$router.push({
        name: "createWorkTalentDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.createWorkTalent {
  background: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 13px;
  .item {
    display: flex;
    height: 204px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    position: relative;
    margin-bottom: 40px;
    .item-l {
      width: 210px;
      height: 100%;
      border-radius: 16px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }
    .item-r {
      flex: 1;
      padding: 30px 12px 0 18px;
      box-sizing: border-box;
      .name {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 12px;
      }
      .intro {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .more {
      position: absolute;
      bottom: 12px;
      right: 12px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 126, 255, 0.58);
      line-height: 26px;
    }
  }
}
</style>
